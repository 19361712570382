import '../scss/navbar.scss';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import { DarkModeContext } from '../context/darkModeContext';

import { useContext } from 'react';
import {Link} from 'react-router-dom';
import { baseUrl } from '../services/envhttp';
import { getErpPic } from '../services/storage';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";

function NavBar() {
  const{dispatch}=useContext(DarkModeContext);
  const{darkMode}=useContext(DarkModeContext);
 // const handle = useFullScreenHandle();

  return (
    <div className='navbar'>
      <div className="wrapper">
     
        <div className='items'>
       
          <div className="item">
         {darkMode?<LightModeOutlinedIcon className='icon' onClick={()=>dispatch({type:"TOGGLE"})} />:<DarkModeOutlinedIcon className='icon' onClick={()=>dispatch({type:"TOGGLE"})} />} 
          </div>
        
          <div className="item">
          <Link to="/notifications" style={{textDecoration:"none"}}>
          <NotificationsNoneOutlinedIcon className='icon'/>
          <div className="counter">1</div>
          </Link>
          </div>
          
          <div className="item">
          <Link to="/profile" style={{textDecoration:"none"}}>
          <img src={getErpPic()? baseUrl + "/public/uploads/"+ getErpPic()
              : require("../images/img_pic_1676713988111.jpg")}
              alt='' className='avatar' />
           </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar