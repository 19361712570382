import { useEffect, useState } from 'react';
import NavBar from '../Layouts/NavBar'
import SideBar from '../Layouts/SideBar'
// import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone';
import FolderIcon from '@mui/icons-material/Folder';
import '../scss/backup.scss';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Link, Navigate } from 'react-router-dom';
//import { getErpId, getUserId } from '../services/storage';
import { getDataApi } from '../services/appApi';


function Backup() {
  //const erp_id=getErpId();
   // const user_id=getUserId();
    const [data, setData] = useState("");
  

  useEffect(() => {
    const fetchSettings = async () => {
      try { 
        let res = await getDataApi("root_folder");
        setData(await res.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchSettings();
  },[]);

  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
}
  return (
    <div className='backup'>
        <SideBar/>
      <div className='backupContainer'> 
      <NavBar/>
     <div className='top'>
     <div className="title">Media Library</div>
     </div>
     <div className="middle">
       <div className="backupIcon">
       {data?data.map((folder) => (
        <div className="folder" key={folder.id}>
           <Link to={'' + folder.id} className='folderLink' style={{ textDecoration: "none" }}>
              <FolderIcon className='icon'/>
              <label>{folder.name}</label>
            </Link>
        </div>
      )):''}
       </div>
     </div>
     <div className="bottom">
   
     </div>
     <FooterPage/>
      </div>
   </div>
  )
}

export default Backup