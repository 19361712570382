import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import Widget from '../components/Widget';
import '../scss/dashboard.scss';
import FooterPage from '../Layouts/FooterPage';
import { useRef } from 'react';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

function DashPage() {
  const ref = useRef(null);
   // const [isLoading,setIsLoading]=useState(false);

    if (!isAuthenticated()) {
      //redirect user to dashboard
      return <Navigate to="/login" />
  }
  return (
    <div className='dashboard'>
      <SideBar/>
      <div className='dashboardContainer'> 
      <NavBar/>
      <LoadingBar color="#f11946" ref={ref} />
    
     <div className='widgets' > 
        <Widget  title="Dash Board" views="srutihariharasubramanian.com On Live" p_link="https://srutihariharasubramanian.com"  link="Sea Your Website"  />
     </div>
     

     <FooterPage/>
      </div>
   </div>
  )
}

export default DashPage