import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import { useState, useEffect,useRef } from 'react';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import '../scss/profile.scss';
import DataTable from '../components/DataTable';
import { toast } from 'react-toastify';
import FooterPage from '../Layouts/FooterPage';
import { baseUrl } from '../services/envhttp';
import { erp_type_lov } from '../services/formLovs';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import { getErpId, getUserId } from '../services/storage';
import { getDataApi, saveApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';

function ProfilePage() {
  const ref = useRef(null);
    const toastOptions = {
        autoClose: 400,
        pauseOnHover: true,
      }

    const erp_id=getErpId();
    const user_id=getUserId();
    const [data, setData] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState();
  // console.log(file);
  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setData({ ...data, [e.target.name]: e.target.files[0], 'erp_id': erp_id, 'user_id': user_id });
  };

    const handleChange=(e)=>{
      setData({ ...data, [e.target.name]: e.target.value,'erp_id': erp_id,'user_id':user_id });
     }

     const submitForm = async(e) => {
      e.preventDefault();
      ref.current.continuousStart();
      let isConfirm = 1;
      if(data.erp_id){
      if(file){
        let formData = new FormData();
        formData.append('img_pic',file);
        await saveApi(formData,"img_upload").then((result) => {
          if (result) {
            if(result.data.message==='Max_File'){
              toast('Maximum 2MB Image only valid', toastOptions);
              isConfirm = 0;
            }if(result.data.message==='No_File'){
              data.pic='';
              isConfirm = 1;
            }else{
              data.pic=result.data.message;
             //console.log(result.data.message);
             // toast(result.data.message, toastOptions);
              isConfirm = 1;
            }
          }
        });
      }
      if(isConfirm){
        saveApi(data,'updateprofile').then((result) => {
          if (result) {
            localStorage.setItem("erp_title",data.erp_title);
				    localStorage.setItem("pic",data.pic);
            localStorage.setItem("erp_type",data.erp_type);  
            toast(result.data.message, toastOptions);
          } else {
            toast('Invalid Request', toastOptions);
          }
        });
        }
      }else{
        toast('Data did not changed', toastOptions);
      }
      ref.current.complete();
    }

    useEffect(() => {
        const fetchLovs = async () => {
          try {
            ref.current.continuousStart();
            let res = await getDataApi("userprofile");
             setData(await res.data[0]);
             setImage(await res.data[0].pic)
             // console.log(baseUrl);
             ref.current.complete();
          } catch (err) {
            console.log(err);
          }
        }
        fetchLovs();
      },[]);

      if (!isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/login" />
    }

  return (
    <div className='profile'>
      <SideBar/>
      <div className="profileContainer">
        <NavBar/>
        <LoadingBar color="#f11946" ref={ref} />
        <h1 className="title">Company Info</h1>
        <div className="top">    
          <div className="left">    
            <div className="item">
            <img src={image ? baseUrl + "/public/uploads/"+ image
              : data.pic ? URL.createObjectURL(data.pic)
              : require("../images/img_pic_1676713988111.jpg")}
              alt=""
              className="itemImg" />
                <h1 className="itemTitle">{data.erp_title}</h1>
            </div>
          </div>
          <div className="right">
           <div className="details">
               <form id="add_form" onSubmit={submitForm}>
                <div className="detailItem">
                  <span className="itemKey">
                     <label htmlFor='file'>
                     <DriveFolderUploadOutlinedIcon className='icon' />
                     </label>
                  </span>
                  <span className="itemValue">
                    <input type="file" id="file" name='pic'
                  onChange={saveFile}
                  style={{ display: "none" }} />
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Title:</span>
                  <span className="itemValue"><input type='text' name='erp_title' onChange={handleChange} value={data.erp_title} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue"><input type='text' name='erp_name' onChange={handleChange} value={data.erp_name} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue"><input type='text' name='erp_contact' onChange={handleChange} value={data.erp_contact} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">GSTIN#:</span>
                  <span className="itemValue"><input type='text' name='gst_number' onChange={handleChange} value={data.gst_number} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">TIN#:</span>
                  <span className="itemValue"><input type='text' name='gst_number' onChange={handleChange} value={data.tin_number} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue"><input type='text' name='erp_address' onChange={handleChange} value={data.erp_address} /></span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Business Type:</span>
                  <span className="itemValue"><select name='erp_type' onChange={handleChange}  value={data.erp_type}>
                 { erp_type_lov.map((option) => (
                    <option value={option.value}>
                      {option.label}
                    </option>
                  ))}  </select></span>
                </div>
                <div className="button">
                <button>Update</button>
                </div>
                </form>
               </div>
          </div>  
        </div>
         <DataTable title="users"/>
        <div className="bottom">
         
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default ProfilePage