export const userInput=[
  {
    "id":100,
    "label":"",
    "name":"empty_field",
    "required":"",
    "type": "hidden",
    "autoComplete":"off",
    "placeholder":""
},
    {
      "id":101,
      "label":"Username",
      "name":"user_name",
      "required":"required",
      "autoComplete":"off",
      "type": "text",
      "placeholder":"User Name"
  },
  {
    "id":102,
    "label":"Contact",
    "name":"contact_number",
    "type": "text",
    "required":"required",
    "autoComplete":"off",
    "placeholder":"Contact number"
  },
  {
    "id":103,
    "label":"Password",
    "name":"password",
    "type": "password",
    "required":"required",
    "autoComplete":"off",
    "placeholder":"New Password"
},
{
  "id":104,
  "label":"Confirm Password",
  "name":"confirm_password",
  "type": "password",
  "required":"required",
  "autoComplete":"off",
  "placeholder":"Confirm Password"
},
  {
    "id":105,
    "label":"User Type",
    "name":"user_type",
    "type": "user_type_lov",
    "required":"required", 
    "autoComplete":"",
    "placeholder":""
  },
  {
    "id":106,
    "label":"User Access",
    "name":"user_access",
    "type": "erp_access_lov",
    "required":"required",
    "autoComplete":"",
    "placeholder":""
  }
];

export const userUpdInput=[
    {
      "id":101,
      "label":"Username",
      "name":"user_name",
      "type": "text",
      "required":"required",
      "autoComplete":"off",
      "placeholder":"User Name"
  },
  {
    "id":102,
    "label":"Contact",
    "name":"contact_number",
    "type": "text",
    "required":"required",
    "autoComplete":"off",
    "placeholder":"Contact number"
  },
  {
    "id":103,
    "label":"Old assword",
    "name":"old_password",
    "type": "password",
    "required":"",
    "autoComplete":"off",
    "placeholder":"Old Password"
},
{
  "id":104,
  "label":"New Password",
  "name":"new_password",
  "type": "password",
  "required":"",
  "autoComplete":"off",
  "placeholder":"Confirm Password"
},
  {
    "id":105,
    "label":"User Type",
    "name":"user_type",
    "type": "user_type_lov",
    "required":"required", 
    "autoComplete":"",
    "placeholder":""
  },
  {
    "id":106,
    "label":"User Access",
    "name":"user_access",
    "type": "erp_access_lov",
    "required":"required",
    "autoComplete":"",
    "placeholder":""
  }
];

export const aboutsInput=[
  {
    "id":102,
    "label":"1st Description",
    "name":"desc_1",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
  },
  {
    "id":103,
    "label":"2nd Description",
    "name":"desc_2",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
},
{
  "id":104,
  "label":"3rd Description",
  "name":"desc_3",
  "type": "richText",
  "required":"",
  "placeholder":"Description"
},
  {
    "id":105,
    "label":"1st Image",
    "name":"img_1",
    "type": "img",
    "required":"", 
    "placeholder":""
  },
  {
    "id":106,
    "label":"2nd Image",
    "name":"img_2",
    "type": "img",
    "required":"",
    "placeholder":""
  },
  {
    "id":107,
    "label":"Note",
    "name":"icon_desc",
    "type": "text",
    "required":"",
    "placeholder":""
  },
  {
    "id":108,
    "label":"Icon",
    "name":"icon",
    "type": "img",
    "required":"",
    "autoComplete":"",
    "placeholder":""
  },
  {
    "id":109,
    "label":"4th Description",
    "name":"desc_4",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
  },
  {
    "id":110,
    "label":"5th Description",
    "name":"desc_5",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
  },
  {
    "id":111,
    "label":"6th Description",
    "name":"desc_6",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
  }
];

export const about_testimonialsInput=[
  {
    "id":101,
    "label":"Description",
    "name":"desc_1",
    "type": "richText",
    "required":"",
    "placeholder":"Description"
},
{
  "id":102,
  "label":"Name",
  "name":"name",
  "type": "text",
  "required":"",
  "placeholder":"Name"
},
{
  "id":103,
  "label":"Comment by",
  "name":"dest",
  "type": "text",
  "required":"",
  "placeholder":""
}
];

export const bannersInput=[
    {
      "id":101,
      "label":"Name",
      "name":"name",
      "type": "text",
      "required":"",
      "placeholder":"Name"
  },
  {
    "id":102,
    "label":"Image",
    "name":"img",
    "type": "img",
    "required":"", 
    "placeholder":""
  }
];

export const contactsInput=[
  {
    "id":101,
    "label":"Email",
      "name":"email",
      "type": "text",
      "required":"",
      "placeholder":"Email"
},
{
  "id":102,
  "label":"Instagram",
      "name":"insta",
      "type": "text",
      "required":"",
      "placeholder":"Instagram"
},
{
  "id":103,
  "label":"Twitter",
  "name":"twiter",
  "type": "text",
  "required":"",
  "placeholder":"Twitter"
},
{
  "id":104,
  "label":"Facebook",
  "name":"facebook",
  "type": "text",
  "required":"",
  "placeholder":"Facebook"
}
];

export const contact_pagesInput=[
  {
    "id":101,
    "label":"1st Line",
      "name":"line_1",
      "type": "text",
      "required":"",
      "placeholder":"Email"
},
{
  "id":102,
  "label":"2nd Line",
      "name":"line_2",
      "type": "text",
      "required":"",
      "placeholder":"Instagram"
}
];

export const home_aboutsInput=[
  {
    "id":101,
    "label":"Title",
    "name":"title",
    "required":"required",
    "type": "text",
    "placeholder":"Tittle"
},
{
  "id":102,
  "label":"1st Description",
  "name":"desc_1",
  "type": "richText",
  "required":"",
  "placeholder":"Description"
},
{
  "id":103,
  "label":"2nd Description",
  "name":"desc_2",
  "type": "richText",
  "required":"",
  "placeholder":"Description"
},
{
  "id":104,
  "label":"1st Image",
  "name":"img_1",
  "type": "img",
  "required":"", 
  "placeholder":""
},
{
  "id":105,
  "label":"2nd Image",
  "name":"img_2",
  "type": "img",
  "required":"",
  "placeholder":""
}
];

export const home_iconsInput=[
  {
    "id":101,
    "label":"Info",
      "name":"info",
      "type": "text",
      "required":"",
      "placeholder":"Info"
},
{
  "id":102,
  "label":"Icon",
      "name":"icon",
      "type": "img",
      "required":"",
      "placeholder":""
}
  ];

export const home_pressesInput=[
  {
    "id":101,
    "label":"Info",
      "name":"info",
      "type": "text",
      "required":"",
      "placeholder":"Info"
},
{
  "id":102,
  "label":"Title",
    "name":"title",
    "type": "text",
    "required":"",
    "placeholder":"Title"
},
{
  "id":103,
  "label":"Image",
      "name":"img",
      "type": "img",
      "required":"",
      "placeholder":""
}];

export const home_testimonialsInput=[
  {
    "id":101,
    "label":"Title",
    "name":"title",
    "type": "text",
    "required":"",
    "placeholder":"Title"
},
{
  "id":102,
  "label":"Description",
  "name":"desc_1",
  "type": "richText",
  "required":"",
  "placeholder":"Description"
},
{
  "id":103,
  "label":"Comment by",
  "name":"name",
  "type": "text",
  "required":"",
  "placeholder":""
},
{
  "id":104,
  "label":"as",
  "name":"dest",
  "type": "text",
  "required":"",
  "placeholder":""
}
];

export const home_worksInput=[
  {
    "id":101,
    "label":"Info",
    "name":"info",
    "type": "text",
    "required":"",
    "placeholder":"Info"
},
{
  "id":102,
  "label":"Year",
  "name":"year",
  "type": "text",
  "required":"",
  "placeholder":"Year"
},
{
  "id":103,
  "label":"Title",
  "name":"title",
  "type": "text",
  "required":"",
  "placeholder":"Info"
},
{
  "id":104,
  "label":"Description",
  "name":"desc_1",
  "type": "text",
  "required":"",
  "placeholder":"Description"
},
{
  "id":105,
  "label":"Produced by",
  "name":"produced_by",
  "type": "text",
  "required":"",
  "placeholder":""
},
{
  "id":106,
  "label":"Language",
  "name":"language",
  "type": "text",
  "required":"",
  "placeholder":""
},
{
  "id":107,
  "label":"Image",
  "name":"img",
  "type": "img",
  "required":"",
  "placeholder":""
},
{
  "id":108,
  "label":"Icon",
  "name":"icon",
  "type": "img",
  "required":"",
  "placeholder":""
}
];

export const pressesInput = [
{
  "id":101,
  "label":"Info",
  "name":"info",
  "type": "text",
  "required":"",
  "placeholder":"Info"
},
{
  "id":102,
  "label":"Title",
  "name":"title",
  "type": "text",
  "required":"",
  "placeholder":"Title"
},
{
  "id":103,
  "label":"Image",
  "name":"img",
  "type": "img",
  "required":"",
  "placeholder":""
}
];
export const worksInput=[
{
    "id":101,
    "label":"Atrical Info",
    "name":"info",
    "type": "text",
    "required":"", 
    "placeholder":"Atrical Infor"
},
{
    "id":102,
    "label":"Artical Title",
    "name":"title",
    "type": "text",
    "required":"", 
    "placeholder":"Artical Title"
},
{
  "id":103,
  "label":"Subject",
  "name":"sub",
  "type": "text",
  "required":"required", 
  "placeholder":"Subject"
},
{
  "id":104,
  "label":"Description",
  "name":"desc_1",
  "type": "richText",
  "required":"required", 
  "placeholder":"Description"
},
{
  "id":105,
  "label":"Produced by",
  "name":"produced_by",
  "type": "text",
  "required":"", 
  "placeholder":"Produced by"
},
{
  "id":106,
  "label":"Language",
  "name":"language",
  "type": "text",
  "required":"", 
  "placeholder":"Language"
},
{
  "id":107,
  "label":"IMDB",
  "name":"imdb",
  "type": "richText",
  "required":"", 
  "placeholder":""
},
{
  "id":108,
  "label":"Youtube",
  "name":"youtube",
  "type": "richText",
  "required":"", 
  "placeholder":""
},
{
  "id":109,
  "label":"Trailer",
  "name":"trailer",
  "type": "richText",
  "required":"", 
  "placeholder":""
},
{
  "id":105,
  "label":"Video Playing Link",
  "name":"url",
  "type": "text",
  "required":"", 
  "placeholder":"Video Playing Link"
},
{
  "id":106,
  "label":"Image",
  "name":"img",
  "type": "img",
  "required":"",
  "placeholder":""
},
{
  "id":107,
  "label":"Icon",
  "name":"icon",
  "type": "img",
  "required":"",
  "placeholder":""
}
];

export const tables=[
  {
    "id":1,
    "name":'abouts'
},
{
  "id":1,
  "name":'about_testimonials'
},
{
  "id":1,
  "name":'banners'
},
{
  "id":1,
  "name":'contacts'
},
{
  "id":1,
  "name":'contact_pages'
},
{
  "id":1,
  "name":'home_abouts'
},
{
  "id":1,
  "name":'home_icons'
},
{
  "id":1,
  "name":'home_presses'
},
{
  "id":1,
  "name":'home_testimonials'
},
{
  "id":1,
  "name":'home_works'
},
{
  "id":1,
  "name":'presses'
},
{
  "id":1,
  "name":'works'
}
];