import { Navigate } from 'react-router-dom';
import DataTable from '../components/DataTable';
import FooterPage from '../Layouts/FooterPage';
import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import '../scss/list.scss';
import { isAuthenticated } from '../services/auth';

function ListPage({title}) {

  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
}
  return (
    <div className='list'>
        <SideBar/>
        <div className='listContainer'>
            <NavBar/>
           {title?<DataTable title={title}/>
           :<div className='tableList'>
            </div>} 
            <FooterPage/>
        </div>
    </div>
  )
}

export default ListPage