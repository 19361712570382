import { Navigate, useParams } from 'react-router-dom';
import '../scss/setting.scss';
import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useState,useEffect,useRef } from 'react';
import { toast } from 'react-toastify';
import { gender_lov,user_type_lov, erp_access_lov } from '../services/formLovs';
import FooterPage from '../Layouts/FooterPage';
import  { baseUrl } from '../services/envhttp';
import { isAuthenticated } from '../services/auth';
import { getErpId, getUserId } from '../services/storage';
import { getSingleDataApi, saveApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';

function UserSettings({ inputs, title }) {
    let { id } =useParams();
    const ref = useRef(null);
  const toastOptions = {
    autoClose: 400,
    pauseOnHover: true,
  }
  const erp_id=getErpId();
  const user_id=getUserId();
  const [userLov, setUserLov] = useState([]);
  const [data, setData] = useState('');
  const [image, setImage] = useState("");
  const [file, setFile] = useState();
  // console.log(file);
  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setData({ ...data, [e.target.name]: e.target.files[0], 'erp_id': erp_id, 'user_id': user_id });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value,'erp_id': erp_id,'user_id':user_id });
  }

  const submitForm = async(e) => {
    e.preventDefault();
    ref.current.continuousStart();
    let isConfirm = 1;
    if(data.erp_id){
    if(file){
      let formData = new FormData();
      formData.append('img_pic',file);
      await saveApi(formData,'img_upload').then((result) => {
        if (result) {
          if(result.data.message==='Max_File'){
            toast('Maximum 2MB Image only valid', toastOptions);
            isConfirm = 0;
          }if(result.data.message==='No_File'){
            data.pic='';
            isConfirm = 1;
          }else{
            data.pic=result.data.message;
            isConfirm = 1;
          }
        }
      });
    }
    if(isConfirm){
    saveApi(data,title).then((result) => {
          if (result) {
            toast(result.data.message, toastOptions);
          } else {
            toast('Invalid Request', toastOptions);
          }
        });
      }
    }else{
      toast('Data did not changed', toastOptions);
    }
    ref.current.complete();
  }

  useEffect(() => {
    const fetchsingles = async () => {
      try {
        ref.current.continuousStart();
        let res =  await getSingleDataApi(title,id);
       // setTempData(res.data[0])
        setData(await res.data[0][0]);
        setImage(await res.data[0][0].pic);
        setUserLov(await res.data[1])
        ref.current.complete();
      } catch (err) {
        console.log(err);
      }
    }
    fetchsingles();
  }, [id,title]);

  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
}

  return (
    <div className='new'>
      <SideBar />
      <div className="newContainer">
        <NavBar />
        <LoadingBar color="#f11946" ref={ref} />
        <div className="top">
          <h1>{title.replace('_', " ")}</h1>
        </div>
        <div className="bottom">
          <div className="left">
          <img src={image ? baseUrl + "/public/uploads/"+ image
              : data.pic ? URL.createObjectURL(data.pic)
              : require("../images/img_pic_1676713988111.jpg")}
              alt=""
              className="itemImg" />
          </div>
          <div className="right">
            <form id="add_form" onSubmit={submitForm}>
              <div className="formInput">
                <label htmlFor='file'>
                  Image <DriveFolderUploadOutlinedIcon className='icon' />
                </label>
                <input type="file" id="file" name='pic'
                  onChange={saveFile}
                  style={{ display: "none" }} />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "gender_lov" ?
                   <select name={input.name}  onChange={handleChange} required='required'value={data[input.name]}>
                   {gender_lov.map((option) => (
                     <option value={option.value}>
                       {option.label}
                     </option>
                   ))}
                 </select>
                      : input.type === "user_type_lov" ?
                      <select name={input.name}  onChange={handleChange} required='required'value={data[input.name]} >
                    {user_type_lov.map((option) => (
                      <option value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                          : input.type === "user_lov" ?
                          <select name={input.name}  onChange={handleChange} required='required' value={data[input.name]} >
                        {userLov.map((option) => (
                          <option value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                   : input.type === "erp_access_lov" ?
                   <select name={input.name} onChange={handleChange} required='required' value={data[input.name]}>
                     {erp_access_lov.map((option) => (
                       <option value={option.value}>
                         {option.label}
                       </option>
                     ))}
                   </select>
                            : <input type={input.type} name={input.name} placeholder={input.placeholder} onChange={handleChange} value={data[input.name]!=='null'?data[input.name]:''} required={input.required} readOnly={input.name==='cash_in_advance'?true:input.name==='opening_amount'?true:input.name==='exp_amount'?true:input.name==='inc_amount'?true:false} autoComplete={input.autoComplete} />
                  }
                </div>
              ))}
              <button>Save</button>
            </form>
          </div>
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default UserSettings