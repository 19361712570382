import { useState,useRef } from 'react';
import '../scss/login.scss';
import { RegisterApi } from '../services/api';
import { isAuthenticated } from '../services/auth';
import { storeUserData } from '../services/storage';
import {  Navigate } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";

function RegisterPage() {
  const ref = useRef(null);
  const initialStateErrors = {
    email:{required:false},
    password:{required:false},
    name:{required:false},
    custom_error:null
};
const [errors,setErrors] = useState(initialStateErrors);

const [loading,setLoading]  =  useState(false);

	const [inputs,setInputs]=useState("");

	const handleChange=(e)=>{
		setInputs({...inputs, [e.target.name]:e.target.value });
	}

  const submitForm = (event)=>{
    event.preventDefault();
    ref.current.continuousStart();
    let errors =initialStateErrors; 
    let hasError = false; 
    if (inputs.name === "") {
        errors.name.required =true;
        hasError=true;
    }
    if (inputs.email === "") {
        errors.email.required =true;
        hasError=true;
    }
    if (inputs.password === "") {
        errors.password.required =true;
        hasError=true;
    }

    if (!hasError) {
        setLoading(true)
        //sending register api request
        RegisterApi(inputs).then((response)=>{
          console.log(response.data);
           storeUserData(response.data.idToken);
        }).catch((err)=>{
           if(err.response.data.error.message==="EMAIL_EXISTS"){
                setErrors({...errors,custom_error:"Already this email has been registered!"})
           }else if(String(err.response.data.error.message).includes('WEAK_PASSWORD')){
                setErrors({...errors,custom_error:"Password should be at least 6 characters!"})
           }

        }).finally(()=>{
          ref.current.complete();
        })
    }
    console.log(initialStateErrors,errors);
    setErrors(errors);
}

   if (isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/login" />
    }
  return (
    <div className='login'>
      <LoadingBar color="#f11946" ref={ref} />
      <div className='loginContainer'> 
     <div className='top'>
     <div className="title"><img src={"./img/ratchus.png"}
              alt=""
              className="itemImg" /></div>
     </div>
     <div className="middle">
       <div className="left">
       <div className="adDesc">
	 Manage your business professionally and digitally with Ratchus. Ratchus assure that this is the best software for your POS billing, strategy & invoice reports, inventory & accounting needs.
         </div>
       </div>
       <div className="right">
		<h4 className="title">Application Register</h4>
		<div className="details">
  					<form onSubmit={submitForm}>
					  <div className="detailItem">
  							<label htmlFor="username" className="itemKey">Name</label>
  							<span className="itemValue">
								<input type="text" name="username" onChange={handleChange} required value={inputs.username}/>
                { errors.name.required?
                            (<span className="text-danger" >
                                    Name is required.
                                </span>):null
                            }
								</span>
  						</div>
              <div className="detailItem">
  							<label htmlFor="username" className="itemKey">Email</label>
  							<span className="itemValue">
								<input type="text" name="email" required onChange={handleChange} value={inputs.email}/>
                { errors.email.required?
                            (<span className="text-danger" >
                                Email is required.
                            </span>):null
                            }
								</span>
  						</div>
  						<div className="detailItem">
  							<label htmlFor="password"  required className="itemKey">Password</label>
							  <span className="itemValue">
  							<input type="password" name="password" onChange={handleChange} value={inputs.password} />
                { errors.password.required?
                            (<span className="text-danger" >
                                Password is required.
                            </span>):null
                            }
							  </span>
  						</div>
						<div className="button">
            <span className="text-danger" >
                            { errors.custom_error?
                            (<p>{errors.custom_error}</p>)
                            :null
                            }
                            </span>
                            {loading ?
                            (<div  className="text-center">
                                <div className="spinner-border text-primary " role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>):null
                            }
  						<button className="button">Register</button>
						  </div>
  					</form>
					  </div>
		  </div>
     </div>
     <div className="bottom">
     <div className="title">Realtime Application and Technical Collection with Highly Unique Solutions</div>
     </div>
      </div>
   </div>
  )
}

export default RegisterPage