import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import { getErpId, getUserId } from '../services/storage';
import { saveApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { erp_access_lov, user_type_lov } from '../services/formLovs';
import '../scss/new.scss';
import PopupPage from './PopupPage';
import { baseUrl } from '../services/envhttp';

function NewPage({ inputs, title }) {
  const ref = useRef(null);
  const toastOptions = {
    autoClose: 400,
    pauseOnHover: true,
  }
  const erp_id = getErpId();
  const user_id = getUserId();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [obtjName, setObtjName] = useState('');

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, 'erp_id': erp_id, 'user_id': user_id });
  //  console.log(data);
  }

  const handleProcedureContentChange = name => content => {
    setData({ ...data, [name]: content, 'erp_id': erp_id, 'user_id': user_id });
  //  console.log(data);
  };

  const togglePopup  = event =>{
    setIsOpen(!isOpen);
    if(event.currentTarget.id !== 'close')
    {
    if(isOpen){
      setData({ ...data, [obtjName] :event.currentTarget.id, 'erp_id': erp_id, 'user_id': user_id });
    console.log(data);
    }else{
      setObtjName(event.currentTarget.id);
    }
  }
  }

  const submitForm = async (e) => {
    e.preventDefault();
    ref.current.continuousStart();
      saveApi(data, title).then((result) => {
        if (result) {
          document.getElementById("add_form").reset();
          setData([]);
          toast(result.data.message, toastOptions);
        } else {
          document.getElementById("add_form").reset();
          setData([]);
          toast('Invalid Request', toastOptions);
        }
      });
    ref.current.complete();
  }


  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
  }

  return (
    <div className='new'>
      <SideBar />
      <div className="newContainer">
        <NavBar />
        <LoadingBar color="#f11946" ref={ref} />
        <div className="top">
          <h1>Add new {title.replace('_', ' ')}</h1>
        </div>
        <div className="bottom">

          <div className="divEdit">
            <form id="add_form" onSubmit={submitForm}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label }</label>
                  {input.type === 'text' ? <input type={input.type} name={input.name} placeholder={input.placeholder} onChange={handleChange} required={input.required} autoComplete={input.autoComplete} />
                    : input.type === 'password' ? <input type={input.type} name={input.name} placeholder={input.placeholder} onChange={handleChange} required={input.required} autoComplete={input.autoComplete} />
                      : input.type === 'richText' ? <div className='newtxEditor'> <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="write your content ...."
                        onChange={handleProcedureContentChange(input.name)}
                        name={input.name}
                        style={{ height: "220px", width: "920px", backgroundColor: "white" }}
                      >
                      </ReactQuill></div>
                        : input.type === "user_type_lov" ?
                          <select name={input.name} onChange={handleChange} required='required' value={data[input.name]} >
                            {user_type_lov.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          : input.type === "erp_access_lov" ?
                            <select name={input.name} onChange={handleChange} required='required' value={data[input.name]}>
                              {erp_access_lov.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            : <div className='imgStyle'>
                              <label htmlFor='file'>
                                <DriveFolderUploadOutlinedIcon onClick={togglePopup} id={input.name} className='icon' />
                              </label>
                              <img src={data[input.name] ? baseUrl + "/public"+ data[input.name]
                                : require("../images/img_pic_1676713988111.jpg")}
                                alt=""
                                className="itemImg" />
                                </div>
                  }
                </div>
              ))}
              <button style={{ width: '200', padding: '10px', border: 'none', backgroundColor: 'teal', color: 'white', fontWeight: 'bold', cursor: 'pointer', marginTop: '10px', fontSize: '20px' }}>Save</button>
            </form>
          </div>
        </div>
        {isOpen && <PopupPage handleClose={togglePopup} />}
        <FooterPage />
      </div>
    </div>
  )
}

export default NewPage;