import '../scss/popup.scss';
import { useEffect, useState,useRef } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import { isAuthenticated } from '../services/auth';
import {  Navigate } from 'react-router-dom';
//import { getErpId, getUserId } from '../services/storage';
import { getDataApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';
import { baseUrl } from '../services/envhttp';

function PopupPage({handleClose}) {
  const ref = useRef(null);
  const [folders,setFolders]=useState([]);
  const [img,setImg]=useState([]);
  const [images,setImages]=useState([]);
  const [root,setRoot]=useState(true);
  
  const changeImages = (id) => {
    setRoot(false);
    setImages(img.filter(item => item.folder_id === id));
  };

  const backImages = () => {
    setRoot(true);
   // setImages(img.filter(item => item.folder_id === id));
  };


  useEffect(() => {
    const fetchSettings = async () => {
      try { 
        let res = await getDataApi("image_folder");
        setFolders(await res.data[0]);
        setImg(await res.data[1]);
        setImages(await res.data[1]);
      } catch (err) {
        console.log(err);
      }
    }
    fetchSettings();
  },[]);

    if (!isAuthenticated()) {
      //redirect user to dashboard
      return <Navigate to="/login" />
  }
  return (
    <div className='popup'>
        <LoadingBar color="#f11946" ref={ref} />
        <div className="box">
            <button className='btn-close' id="close" onClick={handleClose}>x</button>
     <div className='top'>
     <div className="title">Select Media</div>
     </div>
     <div className="middle">
      {root? <div className="backupIcon">
       {folders?folders.map((folder) => (
        <div className="folder" key={folder.id} onClick={()=>changeImages(folder.id)}>
              <FolderIcon className='icon'/>
              <label>{folder.name}</label>
        </div>
      )):''}
       </div>:
       <div className="backupIcon">
              <button className='btnBack' onClick={backImages}>Back</button>
       {images?images.map((img) => (
        <div className="folder" key={img.id} id={img.url} onClick={handleClose} >
        <img src={img.url ? baseUrl + "/public"+ img.url
              : require("../images/img_pic_1676713988111.jpg")}
              alt=""
              className="itemImg" />
         <label>{img.name.substring(0, 10)}</label>
        </div>
 )):''}
       </div>}
     </div>
      </div>
   </div>
  )
}

export default PopupPage