import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import { useState, useEffect,useRef } from 'react';
import '../scss/health.scss';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import { getDataApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';
import { CircularProgress } from '@mui/material';

function HealthPage() {

  const ref = useRef(null);
    const [data,setData]=useState("");

    useEffect(() => {
       // e.preventDefault();
        const fetchLovs = async () => {
          try {
            ref.current.continuousStart();
            let res = await getDataApi("appinfo");
            setData(await res.data[0]);
            ref.current.complete();
          } catch (err) {
            console.log(err);
          }
        }
        fetchLovs();
      },[]);

      if (!isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/login" />
    }
  return (
    <div className='health'>
      <SideBar/>
      <div className="healthContainer">
        <NavBar/>
        <LoadingBar color="#f11946" ref={ref} />
        <h1 className="title">Application Info</h1>
        <div className="top">    
          <div className="left">    
            <div className="item">
                <CircularProgress value={10} text={"10%"} strokeWidth={5} />
                <h1 className="itemTitle">Memory Usage</h1>
            </div> 
          </div>
          <div className="right">
          <div className="details">
           <form id="add_form">
                <div className="detailItem">
                  <span className="itemKey">Application Mail:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Software Ip:</span>
                  <span className="itemValue">{data.ip_address?'XXX.XXX.X.XX':''}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">License:</span>
                  <span className="itemValue">{data.licence_key?'XXXX-XXXX-XXXX-XXXX':''}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Software Expire</span>
                  <span className="itemValue">None</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">App Email:</span>
                  <span className="itemValue">{data.sys_email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Updated Version:</span>
                  <span className="itemValue">{data.updated_version}</span>
                </div>
                <div className="button">
                <button disabled>Close Account</button>
                </div>
                </form>
               </div>
          </div>  
        </div>
        <div className="bottom">
        
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default HealthPage