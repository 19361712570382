import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useState,useEffect,useRef } from 'react';
import { toast } from 'react-toastify';
import {  gender_lov, user_type_lov,  erp_access_lov } from '../services/formLovs';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import { getErpId, getUserId } from '../services/storage';
import { getDataApi, saveApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';
import '../scss/settingnew.scss';

function SettingNewPage({ inputs, title }) {

    const ref = useRef(null);
  const toastOptions = {
    autoClose: 400,
    pauseOnHover: true,
  }
  const erp_id = getErpId();
  const user_id = getUserId();
  const [data, setData] = useState([]);
  const [file, setFile] = useState();
  const [lovData, setLovData] = useState([]);
  // console.log(file);
  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setData({ ...data, [e.target.name]: e.target.files[0], 'erp_id': erp_id, 'user_id': user_id });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value, 'erp_id': erp_id, 'user_id': user_id });
    console.log(data);
  }

  const submitForm = async(e) => {
    e.preventDefault();
    ref.current.continuousStart();
    let isConfirm = 1;
    if(file){
      let formData = new FormData();
      formData.append('img_pic',file);
      await saveApi(formData,"img_upload").then((result) => {
        if (result) {
          if(result.data.message==='Max_File'){
            toast('Maximum 2MB Image only valid', toastOptions);
            isConfirm = 0;
          }if(result.data.message==='No_File'){
            data.pic='';
            isConfirm = 1;
          }else{
            data.pic=result.data.message;
           //console.log(result.data.message);
           // toast(result.data.message, toastOptions);
            isConfirm = 1;
          }
        }
      });
    }
    if(isConfirm){
    saveApi(data,title).then((result) => {
          if (result) {
            document.getElementById("add_form").reset();
            setData([]);
            setFile();
            toast(result.data.message, toastOptions);
          } else {
            document.getElementById("add_form").reset();
            setData([]);
            setFile();
            toast('Invalid Request', toastOptions);
          }
        });
      }
      ref.current.complete();
  }

  
  useEffect(() => {
    const fetchDatas = async () => {
      try {
        ref.current.continuousStart();
       if(title==='notifications'){
          let res =  await getDataApi("user_lov");
          setLovData(await res.data);
          ref.current.complete();
        }else{
          ref.current.complete();
        }
       
      } catch (err) {
        console.log(err);
      }
    }
    fetchDatas();
  }, [title]);
  

  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
}

  return (
    <div className='new'>
      <SideBar />
      <div className="newContainer">
        <NavBar />
        <LoadingBar color="#f11946" ref={ref} />
        <div className="top">
          <h1>Add new {title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img src={data.pic ? URL.createObjectURL(data.pic)
              : require("../images/img_pic_1676713988111.jpg")}
              alt=""
              className="itemImg" />
          </div>
          <div className="right">
            <form id="add_form" onSubmit={submitForm}>
              <div className="formInput">
                <label htmlFor='file'>
                  Image <DriveFolderUploadOutlinedIcon className='icon' />
                </label>
                <input type="file" id="file" name='pic'
                  onChange={saveFile}
                  style={{ display: "none" }} />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "gender_lov" ?
                    <select name={input.name} onChange={handleChange} required='required' >
                      <option value="">Select</option>
                      {gender_lov.map((option) => (
                        <option value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>    
                      : input.type === "user_type_lov" ?
                        <select name={input.name} onChange={handleChange} required='required' >
                          <option value="">Select</option>
                          {user_type_lov.map((option) => (
                            <option value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>  
                            : input.type === "user_lov" ?
                              <select name={input.name} onChange={handleChange} required='required' >
                                <option value="">Select</option>
                                {lovData.map((option) => (
                                  <option value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                                  : input.type === "erp_access_lov" ?
                                  <select name={input.name} onChange={handleChange} required='required'>
                                    <option value="">Select</option>
                                    {erp_access_lov.map((option) => (
                                      <option value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                  : <input type={input.type} name={input.name} placeholder={input.placeholder} onChange={handleChange} required={input.required} autoComplete={input.autoComplete} />
                  }
                </div>
              ))}
              <button>Save</button>
            </form>
          </div>
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default SettingNewPage;