import '../scss/datatable.scss';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useEffect, useState,useRef } from 'react';
import { deleteSingleApi, listApi, publishSingleApi, unPublishSingleApi } from '../services/appApi';
import { toast } from 'react-toastify';

function DataTable({ title }) {
  const ref = useRef(null);
  const [data, setData] = useState("");
  // const[column,setColumn]=useState("");
  const toastOptions = {
    autoClose: 400,
    pauseOnHover: true,
  }

  const handlePublish = async (id) => {
    await publishSingleApi(title, id).then((result) => {
      if (result) {
        toast(result.data.message, toastOptions);
        fetchItems();
      } else {
        toast('Invalid Request', toastOptions);
      }
    });
  }

  const handleUnPublish = async (id) => {
    await unPublishSingleApi(title, id).then((result) => {
      if (result) {
        toast(result.data.message, toastOptions);
        fetchItems();
      } else {
        toast('Invalid Request', toastOptions);
      }
      ref.current.complete();
    });
  }
  const fetchItems = async () => {
    try {
      await listApi(title).then((response)=>{
        //	console.log(response.data);
          if(response.data.length>0){
            setData(response.data);
            console.log(data);
          }else{
            setData("");
           }	
          })
           //  let res = await axios.get(baseUrl + title, { params: { erp_id } });
     // setData(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleDelete = async (id) => {
    await deleteSingleApi("delete_"+title, id).then((result) => {
      if (result) {
        setData(data.filter(item => item.id !== id));
        toast(result.data.message, toastOptions);
      } else {
        toast('Invalid Request', toastOptions);
      }
    });
  }

  let columns;
  switch (title) {
    case "abouts":
      columns = [
        { field: 'id', headerName: 'ID', headerClassName:'gridHead', width: 50 },
        { field: 'desc_1', headerName: 'Description', headerClassName:'gridHead',  width: 500 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
      case "about_testimonials":
      columns = [
        { field: 'id', headerName: 'ID', headerClassName:'gridHead',  width: 50 },
        { field: 'name', headerName: 'Name', headerClassName:'gridHead',  width: 200 },
        { field: 'desc_1', headerName: 'Description',  headerClassName:'gridHead', width: 300 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
      case "banners":
        columns = [
          { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
          { field: 'name', headerName: 'Page Title',  headerClassName:'gridHead', width: 500 },
          {
            field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
              return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
                {params.row.is_active!==1?'UnPublish':'Published'}
              </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
                {params.row.is_active!==1?'UnPublish':'Published'}
              </div>
            }
          }
        ];
        break;
    case "contacts":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'email', headerName: 'Email',  headerClassName:'gridHead', width: 150 },
        { field: 'insta', headerName: 'Insta',  headerClassName:'gridHead', width: 100 },
        { field: 'twiter', headerName: 'Twiter',  headerClassName:'gridHead', width: 100 },
        { field: 'facebook', headerName: 'Facebook',  headerClassName:'gridHead', width: 150 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "contact_pages":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'line_1', headerName: '1st Line',  headerClassName:'gridHead', width: 250 },
        { field: 'line_2', headerName: '1st Line',  headerClassName:'gridHead', width: 250 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "home_abouts":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 200 },
        { field: 'desc_1', headerName: 'Description',  headerClassName:'gridHead', width: 300 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "home_icons":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'info', headerName: 'Information',  headerClassName:'gridHead', width: 500 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "home_presses":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 40 },
        { field: 'info', headerName: 'Information',  headerClassName:'gridHead', width: 200 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 300 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "home_testimonials":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 200 },
        { field: 'desc_1', headerName: 'Description',  headerClassName:'gridHead', width: 300 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "home_works":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'info', headerName: 'Information',  headerClassName:'gridHead', width: 200 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 100 },
        { field: 'desc_1', headerName: 'Description',  headerClassName:'gridHead', width: 200 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "presses":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'info', headerName: 'Information',  headerClassName:'gridHead', width: 200 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 300 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
    case "works":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'info', headerName: 'Information',  headerClassName:'gridHead', width: 200 },
        { field: 'title', headerName: 'Title',  headerClassName:'gridHead', width: 100 },
        { field: 'desc_1', headerName: 'Description',  headerClassName:'gridHead', width: 200 },
        {
          field: 'is_active', headerName: 'Action',  headerClassName:'gridHead', width: 110, renderCell: (params) => {
            return  params.row.is_active!==1?<div className={`cellWithStatus ${'f'+params.row.is_active}`}  onClick={() =>handlePublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>:<div className={`cellWithStatus ${'f'+params.row.is_active}`} onClick={() =>handleUnPublish(params.row.id)} >
              {params.row.is_active!==1?'UnPublish':'Published'}
            </div>
          }
        }
      ];
      break;
      case "logs":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'err_code', headerName: 'Code',  headerClassName:'gridHead', width: 250 },
        { field: 'err_number', headerName: 'Number',  headerClassName:'gridHead', width: 100 },
        { field: 'message', headerName: 'Message',  headerClassName:'gridHead', width: 400 },
        { field: 'err_state', headerName: 'State',  headerClassName:'gridHead', width: 150 },
        { field: 'err_index', headerName: 'Index',  headerClassName:'gridHead', width: 150 },
      ];
      break;
      case "notifications":
      columns = [
        { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
        { field: 'from_user', headerName: 'From',  headerClassName:'gridHead', width: 150 },
        { field: 'msg_to', headerName: 'To',  headerClassName:'gridHead', width: 150 },
        { field: 'msg_subject', headerName: 'Subject',  headerClassName:'gridHead', width: 200 },
        { field: 'message', headerName: 'Messages',  headerClassName:'gridHead', width: 400 },
        {
          field: 'msg_status', headerName: 'Status',  headerClassName:'gridHead', width: 150, renderCell: (params) => {
            return <div className={`cellWithStatus ${params.row.msg_status}`}>
              {params.row.msg_status}
            </div>
          }
        }
      ];
      break;
      case "users":
        columns = [
          { field: 'id', headerName: 'ID',  headerClassName:'gridHead', width: 50 },
          { field: 'user_name', headerName: 'Name',  headerClassName:'gridHead', width: 200 },
          { field: 'user_type', headerName: 'Type',  headerClassName:'gridHead', width: 150 },
          { field: 'contact_number', headerName: 'Contact',  headerClassName:'gridHead', width: 150 },
          { field: 'pc_address', headerName: 'PC',  headerClassName:'gridHead', width: 260 },
          {
            field: 'status', headerName: 'Status',  headerClassName:'gridHead', width: 160, renderCell: (params) => {
              return <div className={`cellWithStatus ${params.row.status}`}>
                {params.row.status}
              </div>
            }
          }
        ];
        break;
    default:
      break;
  }

  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        await listApi(title).then((response)=>{
          //	console.log(response.data);
            if(response.data.length>0){
              setData(response.data);
              console.log(response.data);
            }else{
              setData("");
             }	
            })
             //  let res = await axios.get(baseUrl + title, { params: { erp_id } });
       // setData(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchCategories();
  },[title]);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerClassName:"gridHead", 
      width:120,
      renderCell: (params) => {
        return (
          <div className='cellAction'>
            <Link to={title+'/manage/' + params.row.id} style={{ textDecoration: "none" }}>
              <div className='viewButton'>Edit</div>
            </Link>
           {title==='users'?<div className='deleteButton' disabled onClick={() => handleDelete(params.row.id)} >Delete</div>:
           ""} 
          </div>
        )
      }
    }]

  return (
    <div className='datatable'>
      <div className="dataTableTitle">
        {title.toUpperCase().replace('_',' ')}
      {title!=='abouts' && title!=='banners' && title!=='contacts' && title!=='contact_pages' && title!=='home_abouts' && title!=='home_icons'?<Link to={"new/"+title} className="link">
          Add New
        </Link> :''}  
      </div>
      <DataGrid
      sx={{
        border: 2, // also tried setting to none 
        borderRadius: 2,
        p: 1,
       }}
        className='datagrid'
        rows={data}
        columns={ title!=='logs' && title!=='notifications'?columns.concat(actionColumn):columns }
        pageSize={9}
        rowsPerPageOptions={[10]}
      />
    </div>
  )
}

export default DataTable