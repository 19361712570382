import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import { useState, useEffect,useRef } from 'react';
import '../scss/setting.scss';
import SettingsIcon from '@mui/icons-material/Settings';
import { toast } from 'react-toastify';
import {   erp_lang_lov, erp_type } from '../services/formLovs';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import { getErpId, getUserId } from '../services/storage';
import { getDataApi, saveApi } from '../services/appApi';
import LoadingBar from 'react-top-loading-bar';

function SettingPage() {
  const ref = useRef(null);

    const toastOptions = {
        autoClose: 400,
        pauseOnHover: true,
      }

    const erp_id=getErpId();
    const user_id=getUserId();
    const [data, setData] = useState("");

    let columns = [
  { field: 'erp_type', headerName: 'Application Type:' },
  { field: 'erp_language', headerName: 'Language :' }
];

    const handleChange=(e)=>{
      e.preventDefault();
      setData({ ...data, [e.target.name]: e.target.value,'erp_id': erp_id,'user_id':user_id });
     }

     const submitForm = (e) => {
        e.preventDefault();
        ref.current.continuousStart();
        saveApi(data,"updatesetting").then((result) => {
          if (result) {
            localStorage.setItem("erp_type",data.erp_type);       
            toast(result.data.message, toastOptions);
          } else {
            toast('Invalid Request', toastOptions);
          }
          ref.current.complete();
        });
      }

    useEffect(() => {
        const fetchSettings = async () => {
          try {
            ref.current.continuousStart();
            let res = await getDataApi("erpsetting");
            setData(await res.data);
            ref.current.complete();
          } catch (err) {
            console.log(err);
          }
        }
        fetchSettings();
      },[]);

      if (!isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/login" />
    }

  return (
    <div className='setting'>
      <SideBar/>
      <div className="settingContainer">
        <NavBar/>
        <LoadingBar color="#f11946" ref={ref} />
        <h1 className="title">Company Settings</h1>
        <div className="top">    
          <div className="left">    
            <div className="item">
              <SettingsIcon className='icon'/>
                <h1 className="itemTitle">Settings</h1>
            </div> 
          </div>
          <div className="right">
          <div className="details">
           <form id="add_form" onSubmit={submitForm}>
           {columns.map((column) => (
                <div className="detailItem">
                  <span className="itemKey" key={column.field}>{column.headerName}</span>
                  <span className="itemValue" key={column.field}> 
                  {
                   column.field==='erp_language'?
                   <select  name={column.field} onChange={handleChange}  value={data[column.field]} required='required' >
                   {erp_lang_lov.map((option) => (
                     <option value={option.value}>
                       {option.label}
                     </option>
                   ))}
                   </select>:
                  <select name={column.field} onChange={handleChange}  value={data[column.field]} required='required' >
                 { erp_type.map((option) => (
                    <option value={option.value}>
                      {option.label}
                    </option>
                  ))}  </select>} 
                </span>
                </div>
                 ))}
                <div className="button">
                <button disabled>Update</button>
                </div>
                </form>
               </div>
          </div>  
        </div>
        <div className="bottom">
          
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default SettingPage