import '../scss/footer.scss';

function FooterPage() {
  return (
    <div className='footer'>
    <div className="wrapper">
      <div className='items'>
        <p className='title'>Realtime Application and Technical Collection with Highly Unique Solutions</p>
      </div>
    </div>
  </div>
  )
}

export default FooterPage