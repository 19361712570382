import { useState,useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import '../scss/login.scss';
import { toast } from 'react-toastify';
import LoadingBar from "react-top-loading-bar";
import { storeUserData } from "../services/storage";
import { isAuthenticated } from "../services/auth";
import { loginApi } from '../services/loginApi';

function LoginPage() {
	const ref = useRef(null);
	const toastOptions={
        autoClose:700,
        pauseOnHover:true,
      }
	  let navigate=useNavigate();
	const [user,setUser]=useState("");
	const handleChange=(e)=>{
		setUser({...user, [e.target.name]:e.target.value });
	}

	const submitForm=async(e)=>{
        e.preventDefault();
		ref.current.continuousStart();
		if(user.username && user.password){
			loginApi(user,'login').then((response)=>{
			//	console.log(response.data);
				if(response.data.length>0){
					storeUserData(response.data);
					navigate('/dashboard');
				}else{
					setUser({username:"",password:""});
					toast(response.data.message,toastOptions);
				 }	
			  }).catch((err)=>{
				toast(err,toastOptions);
			  }).finally(()=>{
				ref.current.complete();
				return <Navigate to="/dashboard" />
			  })
		   /*	await axios.post(baseUrl+'login',user).then((result)=>{
			 // console.log(result.data);
			 if(result.data.length>0){
				localStorage.setItem("erp_id",result.data[0][0].id);
				localStorage.setItem("user_id",result.data[1][0].id);
				localStorage.setItem("pic",result.data[0][0].pic);
				localStorage.setItem("erp_txn_type",result.data[0][0].erp_txn_type);
				localStorage.setItem("erp_unit",result.data[0][0].erp_unit);
				localStorage.setItem("erp_currency",result.data[0][0].erp_currency);
				localStorage.setItem("erp_sales_setting",result.data[0][0].erp_sales_setting);
				localStorage.setItem("erp_type",result.data[0][0].erp_type);
				localStorage.setItem("erp_title",result.data[0][0].erp_title);
				localStorage.setItem("user_access",result.data[1][0].user_access);
				setUser({username:"",password:""});
				 navigate('/dashboard');
			 }else{
				setUser({username:"",password:""});
				toast(result.data.message,toastOptions);
			 }	 
			}); */
		}else{
			setUser({username:"",password:""});
			toast(`Username and password should not empty`,toastOptions);
		}
    }

	if (isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/dashboard" />
    }
  return (
    <div className='login'>
		 <LoadingBar color="#f11946" ref={ref} />
      <div className='loginContainer'> 
     <div className='top'>
     <div className="title"><img src={"./img/ratchus.png"}
              alt=""
              className="itemImg" /></div>
     </div>
     <div className="middle">
       <div className="left">
       <div className="adDesc">
	 Manage your Content digitally with Ratchus. Ratchus assure that this is the best software for your website content build needs.
         </div>
       </div>
       <div className="right">
		<h4 className="title">Application Login</h4>
		<div className="details">
  					<form onSubmit={submitForm}>
					  <div className="detailItem">
  							<label htmlFor="username" className="itemKey">Username</label>
  							<span className="itemValue">
								<input type="text" name="username" onChange={handleChange} value={user.username}/>
								</span>
  						</div>
  						<div className="detailItem">
  							<label htmlFor="password" className="itemKey">Password</label>
							  <span className="itemValue">
  							<input type="password" name="password" onChange={handleChange} value={user.password} />
							  </span>
  						</div>
						<div className="button">
  						<button className="button">Login</button>
						  </div>
  					</form>
					  </div>
		  </div>
     </div>
     <div className="bottom">
     <div className="title">Realtime Application and Technical Collection with Highly Unique Solutions</div>
     </div>
      </div>
   </div>
  )
}

export default LoginPage