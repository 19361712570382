import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import "./index.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root=ReactDOM.createRoot(document.getElementById("root"));
 root.render(
        <DarkModeContextProvider>
            <App/>
            <ToastContainer/>
        </DarkModeContextProvider>
);

