//import { getErpId } from './storage';
//const erp_id=getErpId();
export const gender_lov = [
    { value: "FEMALE", label: "Female" },
    { value: "MALE", label: "Male" },
    { value: "OTHER", label: "Other" }
  ];

  export const payment_type_lov = [
    { value: "CASH", label: "By Cash" },
    { value: "BANK", label: "By Bank" },
    { value: "CHECK", label: "By Check" }
  ];

  export const account_type_lov = [
    { value: "CASH", label: "Cash in hand" },
    { value: "SAVING", label: "Cash in Saving Bank" },
    { value: "CURRENT", label: "Cash in Current" },
    { value: "LOAN", label: "Cash in Loan" }
  ];

  export const employee_type_lov = [
    { value: "MANAGER", label: "Manager" },
    { value: "SALES_PERSON", label: "Sales Person" },
    { value: "DRIVER", label: "Driver" },
    { value: "OTHER", label: "Other" }
  ];


  export const erp_type = [
    { value: "PORT_WEBSITE", label: "Portfolio Website" }
   /// { value: "PROD_EXP", label: "Production/Export" }
  ];

  export const erp_access_lov = [
   // { value: "1", label: "All" },
    { value: "2", label: "Edit" },
    //{ value: "3", label: "View" }
  ];

  export const erp_type_lov = [
    { value: "MEDIA", label: "Media" },
   // { value: "RETAILER", label: "Medical Shop" },
   // { value: "EXPORT", label: "Export Company" }
   // { value: "SOFTWARE", label: "Software Company" }
  ];

  export const erp_lang_lov = [
    { value: "ENGLISH", label: "English" }
   // { value: "TAMIL", label: "Tamil" }
  ];

  export const user_type_lov = [
  //  { value: "1", label: "Super Admin" },
    { value: "2", label: "Editor" },
  //  { value: "3", label: "Author" }
  ];