import NavBar from '../Layouts/NavBar';
import SideBar from '../Layouts/SideBar';
import { useState,useRef } from 'react';
import '../scss/contents.scss';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { tables } from '../services/formsrc';
import DataTable from '../components/DataTable';

function ContentPage({title}) {
    const ref = useRef(null);
  //  let { id } = useParams();
    //const [data,setData]=useState("");
    const [active,setActive]=useState('abouts');

    const barClick = async(title) => {
      ref.current.continuousStart();
      setActive(title);
      ref.current.complete();
    };
    

      if (!isAuthenticated()) {
        //redirect user to dashboard
        return <Navigate to="/login" />
    }

  return (
    <div className='contents'>
      <SideBar/>
      <div className="contentsContainer">
        <NavBar/>
        <LoadingBar color="#f11946" ref={ref} />
        <h1 className="title">Contents</h1>
        <div className="top">    
          <div className="left">    
            <div className="item">              
                <h1 className="itemTitle">Content Collections</h1>
                {tables.map((tab) => (
                <div className={active!==tab.name?'itemDetails':'itemActive'} key={tab.id}  onClick={()=>barClick(tab.name)}>
                      <li></li>  {tab.name.toUpperCase().replace('_',' ')}        
                </div>
                ))}
            </div> 
          </div>
          <div className="right">
          <div className="details">
          {title==='contents' || title==='abouts'?<DataTable title={active}/>
           :<div className='tableList'>
              Only for Admin Access
            </div>} 
               </div>
          </div>  
        </div>
        <div className="bottom">
        
        </div>
        <FooterPage/>
      </div>
    </div>
  )
}

export default ContentPage