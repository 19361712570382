import '../scss/sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import BackupTwoToneIcon from '@mui/icons-material/BackupTwoTone';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../context/darkModeContext';
import { useContext } from 'react';
import { logout } from '../services/auth';
import { getErpTitle, getUserAccess } from '../services/storage';

function SideBar() {
  let navigate=useNavigate();
  const{dispatch}=useContext(DarkModeContext);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className='sidebar'>
        <div className='top'>
          <Link to="/dashboard" style={{textDecoration:"none"}}>
          <span className='logo'>
          {getErpTitle()?getErpTitle().substring(0, 6):'Ratchus'}
          </span>
          </Link>
        </div>
        <hr/>
        <div className='center'>
            <ul>
                <p className='title'>Main</p>
                <Link to="/dashboard" style={{textDecoration:"none"}}>
                <li>
                <DashboardIcon className='icon' />
                <span>DashBoard</span>
                </li>
                </Link>
                

                <p className='title'>Activity</p>
                <Link to="/contents" style={{textDecoration:"none"}}>
                <li><ShoppingBasketRoundedIcon className='icon'/><span>Content Manager</span></li>
                </Link>         
                
                <p className='title'>Service</p>
                {(getUserAccess() === '1')?
                <Link to="/backup" style={{textDecoration:"none"}}>
                <li><BackupTwoToneIcon className='icon'/><span>Media Library</span></li>
                </Link>:''}
                <Link to="/notifications" style={{textDecoration:"none"}}>
                <li><NotificationsActiveIcon className='icon'/><span>Notifications</span></li>
                </Link>
                <Link to="/health" style={{textDecoration:"none"}}>
                <li><MonitorHeartIcon className='icon'/><span>System Health</span></li>
                </Link>
                <Link to="/logs" style={{textDecoration:"none"}}>
                <li><PsychologyIcon className='icon'/><span>Logs</span></li>
                </Link>
                {(getUserAccess() === '1')?
                <Link to="/setting" style={{textDecoration:"none"}}>
                <li><SettingsIcon className='icon'/><span>Settings</span></li>
                </Link>:''}
               

                <p className='title'>Account</p>
                {(getUserAccess() === '1')?
                <Link to="/profile" style={{textDecoration:"none"}}>
                <li><AccountBoxIcon className='icon'/><span>Profile</span></li>
                </Link>:''}
                <li onClick={handleLogout}><LogoutIcon className='icon'/><span>Logout</span></li>
            </ul>
        </div>
        <div className='bottom'>
          <div className='colorOption' onClick={()=>dispatch({type:"LIGHT"})}></div>
          <div className='colorOption' onClick={()=>dispatch({type:"DARK"})}></div>
        </div>
    </div>
  )
}

export default SideBar