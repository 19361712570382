// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashPage from './pages/DashPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import ListPage from './pages/ListPage';
import NewPage from './pages/NewPage';
import "./style/dark.scss";
import { useContext } from 'react';
import { DarkModeContext } from './context/darkModeContext';
import Backup from './pages/Backup';
import ProfilePage from './pages/ProfilePage';
import HealthPage from './pages/HealthPage';
import SettingPage from './pages/SettingPage';
import UpdatePage from './pages/UpdatePage';
import UserSettings from './pages/UserSettings';
import ContentPage from './pages/ContentPage';
import { aboutsInput, about_testimonialsInput, bannersInput, contactsInput,userUpdInput,userInput, contact_pagesInput, home_aboutsInput, home_iconsInput, home_pressesInput, home_testimonialsInput, home_worksInput, pressesInput, worksInput } from './services/formsrc';
import SettingNewPage from './pages/SettingNewPage';
import ImagePage from './pages/ImagePage';
// import { useState } from 'react';

function App() {
  //const[dark,setDark]=useState(false);
  const{darkMode}=useContext(DarkModeContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
    <Router>
      <Routes>
           <Route path="/">
             <Route index  element={<LoginPage/>}/>
             <Route path="/register" element={<RegisterPage/>}/>
            <Route path="/dashboard" element={<DashPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/reg" element={<RegisterPage/>}/>
            <Route path="/health" element={<HealthPage/>}/>
            <Route path="/setting" element={<SettingPage/>}/>
            <Route path="/logs" element={<ListPage  title="logs"/>}/>

           <Route path="/profile" >
              <Route index  element={<ProfilePage title="profile"/>}/>
              <Route path="new/users" element={<SettingNewPage inputs={userInput} title="users" />}/>
              <Route path="users/manage/:id" element={<UserSettings inputs={userUpdInput} title="manage_users" />}/>
           </Route>
       
           <Route path="/backup" >
              <Route index  element={<Backup/>} />
              <Route path=":id" element={<ImagePage title="manage_files" />}/>
           </Route>
         
           <Route path="/contents" >
              <Route index  element={<ContentPage title="contents"/>}/>
              <Route path="new/abouts" element={<NewPage inputs={aboutsInput} title="abouts" />}/>
              <Route path="abouts/manage/:id" element={<UpdatePage inputs={aboutsInput} title="manage_abouts" />}/>
              <Route path="new/about_testimonials" element={<NewPage inputs={about_testimonialsInput} title="about_testimonials" />}/>
              <Route path="about_testimonials/manage/:id" element={<UpdatePage inputs={about_testimonialsInput} title="manage_about_testimonials" />}/>
              <Route path="new/banners" element={<NewPage inputs={bannersInput} title="banners" />}/>
              <Route path="banners/manage/:id" element={<UpdatePage inputs={bannersInput} title="manage_banners" />}/>
              <Route path="new/contacts" element={<NewPage inputs={contactsInput} title="contacts" />}/>
              <Route path="contacts/manage/:id" element={<UpdatePage inputs={contactsInput} title="manage_contacts" />}/>
              <Route path="new/contact_pages" element={<NewPage inputs={contact_pagesInput} title="contact_pages" />}/>
              <Route path="contact_pages/manage/:id" element={<UpdatePage inputs={contact_pagesInput} title="manage_contact_pages" />}/>
              <Route path="new/home_abouts" element={<NewPage inputs={home_aboutsInput} title="home_abouts" />}/>
              <Route path="home_abouts/manage/:id" element={<UpdatePage inputs={home_aboutsInput} title="manage_home_abouts" />}/>
              <Route path="new/home_icons" element={<NewPage inputs={home_iconsInput} title="home_icons" />}/>
              <Route path="home_icons/manage/:id" element={<UpdatePage inputs={home_iconsInput} title="manage_home_icons" />}/>
              <Route path="new/home_presses" element={<NewPage inputs={home_pressesInput} title="home_presses" />}/>
              <Route path="home_presses/manage/:id" element={<UpdatePage inputs={home_pressesInput} title="manage_home_presses" />}/>
              <Route path="new/home_testimonials" element={<NewPage inputs={home_testimonialsInput} title="home_testimonials" />}/>
              <Route path="home_testimonials/manage/:id" element={<UpdatePage inputs={home_testimonialsInput} title="manage_home_testimonials" />}/>
              <Route path="new/home_works" element={<NewPage inputs={home_worksInput} title="home_works" />}/>
              <Route path="home_works/manage/:id" element={<UpdatePage inputs={home_worksInput} title="manage_home_works" />}/>
              <Route path="new/presses" element={<NewPage inputs={pressesInput} title="presses" />}/>
              <Route path="presses/manage/:id" element={<UpdatePage inputs={pressesInput} title="manage_presses" />}/>
              <Route path="new/works" element={<NewPage inputs={worksInput} title="works" />}/>
              <Route path="works/manage/:id" element={<UpdatePage inputs={worksInput} title="manage_works" />}/>
           </Route>
       
           <Route path="/content_types" >
              <Route index  element={<ContentPage title="content_types" />}/>
              <Route path="new" element={<NewPage  title="content_type" />}/>
              <Route path="manage/:id" element={<UpdatePage title="manage_content_types" />}/>
           </Route>
           <Route path="/notifications" >
              <Route index  element={<ListPage title="notifications" />}/>
              <Route path="new" element={<NewPage  title="notifications" />}/>
           </Route>
          </Route>
       </Routes>
    </Router>
  </div>
  );
}

export default App;
