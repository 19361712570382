import '../scss/widget.scss';

function Widget({ title,views,p_link,link }) {

  return (
    <div className='widget'>
        <div className='left'>
            <span className='title'>{title}</span>
            <span className='counter'> {views}</span>
            <a href={(p_link)} target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
            <span className='link'>{link}</span>
            </a>
        </div>
    </div>
  )
}

export default Widget