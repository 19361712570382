import { useEffect, useState } from 'react';
import NavBar from '../Layouts/NavBar'
import SideBar from '../Layouts/SideBar'
import '../scss/backup.scss';
import FooterPage from '../Layouts/FooterPage';
import { isAuthenticated } from '../services/auth';
import { Navigate, useParams } from 'react-router-dom';
import {  getSingleDataApi } from '../services/appApi';
import { baseUrl } from '../services/envhttp';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
// import { toast } from 'react-toastify';

function ImagePage({title}) {

    let { id } =useParams();

   /* const toastOptions = {
      autoClose: 400,
      pauseOnHover: true,
    } */
    const [data, setData] = useState();

   // const [image, setImage] = useState([]);
   

  useEffect(() => {
    const fetchSettings = async () => {
      try { 
        let res = await getSingleDataApi(title,id);
        setData(await res.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchSettings();
  },[id,title]);

  /*
  const handleSubmit = async() => {

    let formData = new FormData();
  //  formData.append('img_pic',image);
  Array.from(image).forEach(item => {
    let split = item.name.split('.');
    split.pop();
    let finalName = split.join(".");
    formData.append(finalName, item)
    console.log(formData);
  })
    
    await saveApi(formData,'img_upload').then((result) => {
      if (result) {
        if(result.data.message==='Max_File'){
          toast('Maximum 2MB Image only valid', toastOptions);
        }if(result.data.message==='No_File'){
          setImage([]);
        }else{
          data.pic=result.data.message;
        }
      }
    }).catch(err => {
      console.log(err)
    })
  } */

  if (!isAuthenticated()) {
    //redirect user to dashboard
    return <Navigate to="/login" />
}

  return (
    <div className='backup'>
    <SideBar/>
  <div className='backupContainer'> 
  <NavBar/>
 <div className='top'>
 <div className="title">Media Library</div>
 </div>
 <div className="middle">
 <label htmlFor='file'>
   Upload <DriveFolderUploadOutlinedIcon className='icon' />
  </label>

 <div className="backupIcon">
       {data?data.map((img) => (
        <div className="folder" key={img.id}>
        <img src={img.url ? baseUrl + "/public"+ img.url
              : require("../images/img_pic_1676713988111.jpg")}
              alt=""
              className="itemImg" />
         <label>{img.name.substring(0, 10)}</label>
        </div>
 )):''}
       </div>
       
 </div>
 <div className="bottom">
 
  
 </div>
 <FooterPage/>
  </div>
</div>
  )
}

export default ImagePage